import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { scrollToTop } from '../utils/scroll';

export const useScrollToTop = (smooth: boolean = true) => {
    const { pathname } = useLocation();

    useEffect(() => {
        scrollToTop(smooth);
    }, [pathname, smooth]);
}; 