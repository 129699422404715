import { Brain, BarChart2, Heart } from 'lucide-react';

export const services = [
  {
    icon: Brain,
    title: 'Enterprise Solutions',
    description: 'Comprehensive assessment solutions for corporate teams and leadership development.'
  },
  {
    icon: BarChart2,
    title: 'Student Services',
    description: 'Career counseling and assessment services tailored for academic success.'
  },
  {
    icon: Heart,
    title: 'Personal Services',
    description: 'Individual and family counseling with personalized situation analysis.'
  }
] as const;