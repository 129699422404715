import React from 'react';
import Container from '../components/ui/Container';
import { Heart, Users2, Brain, Smile } from 'lucide-react';

const services = [
  {
    icon: Heart,
    title: 'Personal Situation Analysis',
    description: 'In-depth assessment of individual circumstances and challenges.',
    features: [
      'Personality assessment',
      'Emotional intelligence evaluation',
      'Stress management analysis',
      'Personal growth planning'
    ]
  },
  {
    icon: Users2,
    title: 'Family Counseling',
    description: 'Professional guidance for improving family dynamics.',
    features: [
      'Family dynamics assessment',
      'Communication pattern analysis',
      'Conflict resolution strategies',
      'Relationship enhancement'
    ]
  },
  {
    icon: Brain,
    title: 'Life Skills Development',
    description: 'Practical skills for personal effectiveness.',
    features: [
      'Decision-making skills',
      'Problem-solving abilities',
      'Interpersonal effectiveness',
      'Emotional regulation'
    ]
  },
  {
    icon: Smile,
    title: 'Well-being Support',
    description: 'Holistic approach to personal well-being.',
    features: [
      'Mental health assessment',
      'Work-life balance strategies',
      'Stress management techniques',
      'Personal resilience building'
    ]
  }
];

const PersonalServices = () => {
  return (
    <div className="pt-16">
      <div className="bg-primary text-white py-20">
        <Container>
          <h1 className="text-4xl font-bold mb-6">Personal Services</h1>
          <p className="text-xl opacity-90 max-w-2xl">
            Personalized assessment and counseling for individual growth and family harmony.
          </p>
        </Container>
      </div>

      <Container className="py-20">
        <div className="grid md:grid-cols-2 gap-8">
          {services.map((service, index) => {
            const Icon = service.icon;
            return (
              <div key={index} className="bg-white rounded-lg shadow-lg p-6">
                <div className="w-12 h-12 bg-primary/10 rounded-lg flex items-center justify-center mb-4">
                  <Icon className="w-6 h-6 text-primary" />
                </div>
                <h2 className="text-xl font-semibold mb-3">{service.title}</h2>
                <p className="text-gray-600 mb-4">{service.description}</p>
                <ul className="space-y-2">
                  {service.features.map((feature, idx) => (
                    <li key={idx} className="flex items-center text-sm text-gray-600">
                      <span className="w-1.5 h-1.5 bg-primary rounded-full mr-2" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export default PersonalServices;