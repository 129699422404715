import React from 'react';

interface ClientCardProps {
  name: string;
  logo: string;
  alt: string;
}

const ClientCard: React.FC<ClientCardProps> = ({ name, logo, alt }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
      <img
        src={logo}
        alt={alt}
        className="w-full h-12 object-contain filter grayscale hover:grayscale-0 transition-all duration-300"
      />
    </div>
  );
};

export default ClientCard;