import React from 'react';
import Container from './ui/Container';
import { Award, BookOpen, Building2, Users2 } from 'lucide-react';
import milindImage from '../assets/images/milind.jpg';

const achievements = [
  {
    icon: Building2,
    title: 'Corporate Experience',
    description: 'Extensive work with leading organizations including Kotak Group'
  },
  {
    icon: BookOpen,
    title: 'Academic Excellence',
    description: 'Faculty positions at prestigious institutions including IIM Nagpur'
  },
  {
    icon: Users2,
    title: 'Training Expertise',
    description: 'Comprehensive corporate training programs and workshops'
  },
  {
    icon: Award,
    title: 'Industry Recognition',
    description: 'Successfully implemented HINDALCO program and other major initiatives'
  }
];

const AboutSection = () => {
  return (
    <section id="about" className="py-20 bg-gray-50">
      <Container>
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <div>
            <h2 className="text-3xl font-bold mb-6">Dr. Milind Apte</h2>
            <p className="text-gray-600 mb-6">
              A distinguished expert in psychometric assessment and analysis, Dr. Milind Apte brings decades of experience in both government and private sectors. His unique approach combines technical expertise with emotional intelligence considerations.
            </p>
            <div className="grid grid-cols-2 gap-4">
              {achievements.map((achievement, index) => {
                const Icon = achievement.icon;
                return (
                  <div key={index} className="bg-white p-4 rounded-lg shadow-sm">
                    <Icon className="w-6 h-6 text-primary mb-2" />
                    <h3 className="font-semibold text-sm mb-1">{achievement.title}</h3>
                    <p className="text-xs text-gray-600">{achievement.description}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="relative">
            <div className="aspect-square rounded-lg overflow-hidden shadow-xl">
              <img
                src={milindImage}
                alt="Dr. Milind Apte"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="absolute -bottom-6 -left-6 bg-white p-4 rounded-lg shadow-lg">
              <p className="text-sm font-semibold text-primary">20+ Years Experience</p>
              <p className="text-xs text-gray-600">in Psychometric Assessment</p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default AboutSection;