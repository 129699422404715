import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import ClientSection from './components/ClientSection';
import ServicesSection from './components/ServicesSection';
import ProcessSection from './components/ProcessSection';
import AboutSection from './components/AboutSection';
import FAQSection from './components/FAQSection';
import ContactSection from './components/ContactSection';
import Footer from './components/Footer';
import EnterpriseServices from './pages/EnterpriseServices';
import StudentServices from './pages/StudentServices';
import PersonalServices from './pages/PersonalServices';
import ScrollToTop from './components/ScrollToTop';

const HomePage = () => (
  <>
    <Hero />
    <ClientSection />
    <ServicesSection />
    <ProcessSection />
    <AboutSection />
    <FAQSection />
    <ContactSection />
  </>
);

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="min-h-screen bg-white">
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/enterprise" element={<EnterpriseServices />} />
          <Route path="/student" element={<StudentServices />} />
          <Route path="/personal" element={<PersonalServices />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;