import siemensLogo from '../assets/images/clients/siemens.png';
import kpitLogo from '../assets/images/clients/kpit.png';
import mahindraLogo from '../assets/images/clients/mahindra.png';
import arcelorLogo from '../assets/images/clients/arcelor.png';
import ajmeraLogo from '../assets/images/clients/ajmera.png';
import hindalcoLogo from '../assets/images/clients/hindalco.png';

export const clients = [
  {
    name: 'Siemens',
    logo: siemensLogo,
    alt: 'Siemens Logo'
  },
  {
    name: 'KPIT',
    logo: kpitLogo,
    alt: 'KPIT Logo'
  },
  {
    name: 'Mahindra',
    logo: mahindraLogo,
    alt: 'Mahindra Logo'
  },
  {
    name: 'ArcelorMittal',
    logo: arcelorLogo,
    alt: 'ArcelorMittal Logo'
  },
  {
    name: 'Ajmera',
    logo: ajmeraLogo,
    alt: 'Ajmera Logo'
  },
  {
    name: 'Hindalco',
    logo: hindalcoLogo,
    alt: 'Hindalco Logo'
  }
] as const;