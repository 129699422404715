import React from 'react';
import Container from '../components/ui/Container';
import { Users, Briefcase, Brain, Target } from 'lucide-react';
import { useScrollToTop } from '../hooks/useScrollToTop';
const services = [
  {
    icon: Users,
    title: 'Team Composition & Coaching',
    description: 'Optimize team dynamics through comprehensive assessment and targeted coaching interventions.',
    features: [
      'Team dynamics analysis',
      'Role compatibility assessment',
      'Communication pattern evaluation',
      'Team building recommendations'
    ]
  },
  {
    icon: Briefcase,
    title: 'Leadership Development',
    description: 'Develop effective leaders through assessment-based development programs.',
    features: [
      'Leadership potential assessment',
      'Competency mapping',
      'Succession planning',
      'Executive coaching'
    ]
  },
  {
    icon: Brain,
    title: 'Cultural Development',
    description: 'Foster a positive organizational culture aligned with business objectives.',
    features: [
      'Cultural fit assessment',
      'Values alignment',
      'Change readiness evaluation',
      'Culture transformation roadmap'
    ]
  },
  {
    icon: Target,
    title: 'Recruitment Solutions',
    description: 'Make informed hiring decisions considering environment, expertise, and culture.',
    features: [
      'Candidate assessment',
      'Role-culture fit analysis',
      'Potential evaluation',
      'Team integration planning'
    ]
  }
];

const EnterpriseServices = () => {
  useScrollToTop();
  return (
    <div className="pt-16">
      <div className="bg-primary text-white py-20">
        <Container>
          <h1 className="text-4xl font-bold mb-6">Enterprise Solutions</h1>
          <p className="text-xl opacity-90 max-w-2xl">
            Comprehensive assessment and development solutions tailored for corporate excellence.
          </p>
        </Container>
      </div>

      <Container className="py-20">
        <div className="grid md:grid-cols-2 gap-8">
          {services.map((service, index) => {
            const Icon = service.icon;
            return (
              <div key={index} className="bg-white rounded-lg shadow-lg p-6">
                <div className="w-12 h-12 bg-primary/10 rounded-lg flex items-center justify-center mb-4">
                  <Icon className="w-6 h-6 text-primary" />
                </div>
                <h2 className="text-xl font-semibold mb-3">{service.title}</h2>
                <p className="text-gray-600 mb-4">{service.description}</p>
                <ul className="space-y-2">
                  {service.features.map((feature, idx) => (
                    <li key={idx} className="flex items-center text-sm text-gray-600">
                      <span className="w-1.5 h-1.5 bg-primary rounded-full mr-2" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export default EnterpriseServices;