import React from 'react';
import Container from '../components/ui/Container';
import { GraduationCap, Brain, Target, BookOpen } from 'lucide-react';

const services = [
  {
    icon: GraduationCap,
    title: 'Career Counseling',
    description: 'Expert guidance for making informed career decisions.',
    features: [
      'Interest assessment',
      'Aptitude evaluation',
      'Career path mapping',
      'Industry alignment'
    ]
  },
  {
    icon: Brain,
    title: 'Psychometric Assessment',
    description: 'Comprehensive evaluation of abilities and preferences.',
    features: [
      'Cognitive ability testing',
      'Personality profiling',
      'Learning style analysis',
      'Emotional intelligence assessment'
    ]
  },
  {
    icon: Target,
    title: 'Academic Guidance',
    description: 'Strategic support for academic excellence.',
    features: [
      'Study pattern analysis',
      'Learning approach optimization',
      'Subject selection guidance',
      'Performance improvement plans'
    ]
  },
  {
    icon: BookOpen,
    title: 'Skill Development',
    description: 'Targeted programs for essential skill enhancement.',
    features: [
      'Soft skills assessment',
      'Communication skills',
      'Leadership potential',
      'Time management'
    ]
  }
];

const StudentServices = () => {
  return (
    <div className="pt-16">
      <div className="bg-secondary text-white py-20">
        <Container>
          <h1 className="text-4xl font-bold mb-6">Student Services</h1>
          <p className="text-xl opacity-90 max-w-2xl">
            Empowering students with insights and guidance for academic and career success.
          </p>
        </Container>
      </div>

      <Container className="py-20">
        <div className="grid md:grid-cols-2 gap-8">
          {services.map((service, index) => {
            const Icon = service.icon;
            return (
              <div key={index} className="bg-white rounded-lg shadow-lg p-6">
                <div className="w-12 h-12 bg-secondary/10 rounded-lg flex items-center justify-center mb-4">
                  <Icon className="w-6 h-6 text-secondary" />
                </div>
                <h2 className="text-xl font-semibold mb-3">{service.title}</h2>
                <p className="text-gray-600 mb-4">{service.description}</p>
                <ul className="space-y-2">
                  {service.features.map((feature, idx) => (
                    <li key={idx} className="flex items-center text-sm text-gray-600">
                      <span className="w-1.5 h-1.5 bg-secondary rounded-full mr-2" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export default StudentServices;