import React from 'react';
import Container from './ui/Container';
import ClientCard from './ClientCard';
import { clients } from '../constants/clients';

const ClientSection = () => {
  return (
    <section id="clients" className="py-16 bg-gray-50">
      <Container>
        <h2 className="text-3xl font-bold text-center mb-12">Trusted by Industry Leaders</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-8">
          {clients.map((client, index) => (
            <ClientCard
              key={index}
              name={client.name}
              logo={client.logo}
              alt={client.alt}
            />
          ))}
        </div>
      </Container>
    </section>
  );
};

export default ClientSection;