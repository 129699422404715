import React from 'react';
import Container from './ui/Container';
import Button from './ui/Button';
import ServiceCard from './ServiceCard';
import { services } from '../constants/services';
import { scrollToSection } from '../utils/scroll';

const Hero = () => {
  const handleLearnMore = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    scrollToSection('services');
  };

  const handleGetStarted = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    scrollToSection('contact');
  };

  return (
    <div className="pt-24 pb-16 bg-gradient-to-br from-primary/5 to-secondary/5">
      <Container>
        <div className="text-center">
          <h1 className="text-4xl md:text-6xl font-bold text-gray-900 mb-6">
            Advanced Psychometric Assessment
            <span className="text-primary"> & Analysis</span>
          </h1>
          <p className="text-xl text-gray-600 mb-8 max-w-3xl mx-auto">
            Unlock human potential through comprehensive psychometric assessments that consider both
            technical capabilities and emotional intelligence.
          </p>
          <div className="flex flex-wrap justify-center gap-4">
            <Button onClick={handleGetStarted}>Get Started</Button>
            <Button variant="outline" onClick={handleLearnMore}>
              Learn More
            </Button>
          </div>
        </div>

        <div className="mt-16 grid md:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <ServiceCard
              key={index}
              icon={service.icon}
              title={service.title}
              description={service.description}
            />
          ))}
        </div>
      </Container>
    </div>
  );
};

export default Hero;