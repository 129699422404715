export const faqs = [
  {
    question: 'What is psychometric assessment?',
    answer: 'Psychometric assessment is a standardized method used to measure individuals\' mental capabilities, behavioral styles, and personality traits. It helps in understanding potential, aptitude, and emotional intelligence.'
  },
  {
    question: 'How long does an assessment take?',
    answer: 'A typical assessment session takes 60-90 minutes. However, the duration may vary based on the type of assessment and specific requirements.'
  },
  {
    question: 'Are online sessions available?',
    answer: 'Yes, we offer both online and in-person assessment sessions. Online sessions are conducted through secure video conferencing platforms.'
  },
  {
    question: 'How do I prepare for an assessment?',
    answer: 'No specific preparation is required. We recommend getting adequate rest and approaching the assessment with an open mind for best results.'
  },
  {
    question: 'What happens after the assessment?',
    answer: 'You\'ll receive a detailed report followed by a counseling session to discuss the results and receive personalized recommendations.'
  },
  {
    question: 'How are corporate assessments different?',
    answer: 'Corporate assessments are tailored to organizational needs, focusing on team dynamics, leadership potential, and cultural fit while considering business objectives.'
  }
] as const;