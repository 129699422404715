import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { scrollToSection } from '../utils/scroll';
import logo from '../assets/images/logo.png';

const Navbar = () => {
  const location = useLocation();

  const handleNavClick = (e: React.MouseEvent<HTMLAnchorElement>, sectionId: string) => {
    e.preventDefault();
    if (location.pathname !== '/') {
      window.location.href = '/#' + sectionId;
    } else {
      scrollToSection(sectionId);
    }
  };

  const handleScheduleAssessment = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (location.pathname !== '/') {
      window.location.href = '/#contact';
    } else {
      scrollToSection('contact');
    }
  };

  return (
    <nav className="bg-white shadow-md fixed w-full z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex items-center">
              <img src={logo} alt="Introspects Logo" className="h-14 w-auto" />
              {/* <span className="ml-2 text-2xl font-bold text-primary">Introspects</span> */}
            </Link>
          </div>
          <div className="hidden md:flex items-center space-x-8">
            <a
              href="#services"
              onClick={(e) => handleNavClick(e, 'services')}
              className="text-gray-700 hover:text-primary"
            >
              Services
            </a>
            <a
              href="#clients"
              onClick={(e) => handleNavClick(e, 'clients')}
              className="text-gray-700 hover:text-primary"
            >
              Clients
            </a>
            <a
              href="#process"
              onClick={(e) => handleNavClick(e, 'process')}
              className="text-gray-700 hover:text-primary"
            >
              Process
            </a>
            <a
              href="#about"
              onClick={(e) => handleNavClick(e, 'about')}
              className="text-gray-700 hover:text-primary"
            >
              About
            </a>
            <a
              href="#contact"
              onClick={(e) => handleNavClick(e, 'contact')}
              className="text-gray-700 hover:text-primary"
            >
              Contact
            </a>
            <button
              onClick={handleScheduleAssessment}
              className="bg-primary text-white px-4 py-2 rounded-md hover:bg-primary-light transition"
            >
              Schedule Assessment
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;