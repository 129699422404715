import { MessageSquare, Calendar, BarChart, Video, Users } from 'lucide-react';

export const processSteps = [
  {
    icon: MessageSquare,
    title: 'Initial Contact',
    description: 'Begin with a consultation to understand your specific needs and objectives.'
  },
  {
    icon: Calendar,
    title: 'Assessment Scheduling',
    description: 'Schedule your assessment at a convenient time, either online or in-person.'
  },
  {
    icon: BarChart,
    title: 'Score Generation',
    description: 'Receive detailed psychometric analysis and comprehensive scoring.'
  },
  {
    icon: Video,
    title: 'Counselling Session',
    description: 'Discuss results and receive personalized guidance from our experts.'
  },
  {
    icon: Users,
    title: 'Follow-up Support',
    description: 'Ongoing support and guidance to help implement recommendations.'
  }
] as const;