import React from 'react';
import { Link } from 'react-router-dom';
import Container from './ui/Container';
import { Brain, Users, Briefcase, GraduationCap, Heart, Users2 } from 'lucide-react';

const services = [
  {
    icon: Users,
    title: 'Enterprise Solutions',
    description: 'Optimize team dynamics and performance',
    path: '/enterprise'
  },
  {
    icon: GraduationCap,
    title: 'Student Services',
    description: 'Guide students towards optimal career paths',
    path: '/student'
  },
  {
    icon: Heart,
    title: 'Personal Services',
    description: 'Individual situation assessment',
    path: '/personal'
  }
];

const ServiceCard = ({ icon: Icon, title, description, path }) => (
  <Link
    to={path}
    className="block bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition transform hover:-translate-y-1"
  >
    <div className="p-6">
      <div className="w-12 h-12 rounded-full bg-primary/10 flex items-center justify-center mb-4">
        <Icon className="w-6 h-6 text-primary" />
      </div>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  </Link>
);

const ServicesSection = () => {
  return (
    <section id="services" className="py-20 bg-gray-50">
      <Container>
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Our Services</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Comprehensive psychometric assessment solutions tailored for different needs
          </p>
        </div>
        <div className="grid md:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>
      </Container>
    </section>
  );
};

export default ServicesSection;