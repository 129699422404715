import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Container from './ui/Container';
import { scrollToSection } from '../utils/scroll';
import logo from '../assets/images/logo.png';

const Footer = () => {
  const location = useLocation();

  const handleHashLinkClick = (e: React.MouseEvent<HTMLAnchorElement>, sectionId: string) => {
    e.preventDefault();
    if (location.pathname !== '/') {
      window.location.href = '/#' + sectionId;
    } else {
      scrollToSection(sectionId);
    }
  };

  return (
    <footer className="bg-gray-900 text-gray-300">
      <Container className="py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Brand */}
          <div className="space-y-4">
            <Link to="/" className="flex items-center">
              <img
                src={logo}
                alt="Introspects Logo"
                className="h-16 w-auto brightness-0 invert" // Makes the logo white in dark background
              />
              {/* <span className="ml-2 text-2xl font-bold text-white">Introspects</span> */}
            </Link>
            <p className="text-sm">
              Advanced psychometric assessment and analysis for personal and professional growth.
            </p>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-white font-semibold mb-4">Services</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/enterprise" className="hover:text-primary">
                  Enterprise Solutions
                </Link>
              </li>
              <li>
                <Link to="/student" className="hover:text-primary">
                  Student Services
                </Link>
              </li>
              <li>
                <Link to="/personal" className="hover:text-primary">
                  Personal Services
                </Link>
              </li>
            </ul>
          </div>

          {/* Site Links */}
          <div>
            <h3 className="text-white font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <a
                  href="#about"
                  onClick={(e) => handleHashLinkClick(e, 'about')}
                  className="hover:text-primary"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="#process"
                  onClick={(e) => handleHashLinkClick(e, 'process')}
                  className="hover:text-primary"
                >
                  Our Process
                </a>
              </li>
              <li>
                <a
                  href="#clients"
                  onClick={(e) => handleHashLinkClick(e, 'clients')}
                  className="hover:text-primary"
                >
                  Our Clients
                </a>
              </li>
              <li>
                <a
                  href="#contact"
                  onClick={(e) => handleHashLinkClick(e, 'contact')}
                  className="hover:text-primary"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>

          {/* Contact */}
          <div>
            <h3 className="text-white font-semibold mb-4">Contact</h3>
            <ul className="space-y-2 text-sm">
              <li>Nagpur: +91 94228 03613</li>
              <li>Pune: +91 94228 03613</li>
              <li>
                <a href="mailto:milind.counselor@gmail.com" className="hover:text-primary">
                  milind.counselor@gmail.com
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Copyright */}
        <div className="border-t border-gray-800 mt-12 pt-8 text-center text-sm">
          <p>&copy; {new Date().getFullYear()} Introspects. All rights reserved.</p>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;