import React from 'react';
import { MapPin, Phone, Mail } from 'lucide-react';

interface LocationCardProps {
  city: string;
  address: string;
}

const LocationCard: React.FC<LocationCardProps> = ({ city, address }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-lg">
      <h3 className="text-xl font-semibold mb-4 text-primary">{city} Office</h3>
      <div className="space-y-4">
        <div className="flex items-start">
          <MapPin className="w-5 h-5 text-primary mt-1 flex-shrink-0" />
          <p className="ml-3 text-gray-600 whitespace-pre-line">{address}</p>
        </div>
        <div className="flex items-center">
          <Phone className="w-5 h-5 text-primary flex-shrink-0" />
          <a href="tel:+919422803613" className="ml-3 text-gray-600 hover:text-primary">
            +91 94228 03613
          </a>
        </div>
        <div className="flex items-center">
          <Mail className="w-5 h-5 text-primary flex-shrink-0" />
          <a href="mailto:milind.counselor@gmail.com" className="ml-3 text-gray-600 hover:text-primary">
            milind.counselor@gmail.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default LocationCard;