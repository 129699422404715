import React from 'react';
import Container from '../ui/Container';
import FAQItem from './FAQItem';
import { faqs } from '../../constants/faqs';

const FAQSection = () => {
  return (
    <section id="faq" className="py-20 bg-white">
      <Container>
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Frequently Asked Questions</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Find answers to common questions about our assessment process and services.
          </p>
        </div>
        
        <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-lg p-6">
          {faqs.map((faq, index) => (
            <FAQItem key={index} {...faq} />
          ))}
        </div>
      </Container>
    </section>
  );
};

export default FAQSection;