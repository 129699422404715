export const locations = [
  {
    city: 'Nagpur',
    address: '29, Vakratunda Apartment, First Floor\nAbove Shree Laxmi Super Shoppe\nPande Layout, Khamla\nNagpur, Maharashtra 440025',
    coordinates: {
      lat: 21.1458,
      lng: 79.0882
    }
  },
  {
    city: 'Pune',
    address: 'F702, Windsor\nBalewadi Phata Baner\nPune, Maharashtra 411045',
    coordinates: {
      lat: 18.5596,
      lng: 73.7433
    }
  }
] as const;