import React from 'react';
import Container from '../ui/Container';
import LocationCard from './LocationCard';
import { locations } from '../../constants/locations';

const ContactSection = () => {
  return (
    <section id="contact" className="py-20 bg-gray-50">
      <Container>
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Contact Us</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Get in touch with us for inquiries about our services or to schedule an assessment.
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8">
          {locations.map((location, index) => (
            <LocationCard key={index} {...location} />
          ))}
        </div>
      </Container>
    </section>
  );
};

export default ContactSection;